import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalstorageService } from '../localstorage/localstorage.service';
import { IClient } from '../../../models/dtos/get/client.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedUserService {
  private _client: BehaviorSubject<IClient | null> = new BehaviorSubject<IClient | null>(
    null
  );

  public client$: Observable<IClient | null> = this._client.asObservable();

  constructor(private _localStorageService: LocalstorageService<IClient>) { }

  public get() {
    let client: IClient | null = this._localStorageService.getItem('client');
    if (!client) {

    } else {
      this._client.next(client);
    }
  }

  public set(login: IClient | null) {
    this._client.next(login);
  }
}
