import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService<T> {
  constructor() {}

  public setItem(key: string, item: T): void {
    let stringifiedValue = JSON.stringify(item);
    localStorage.setItem(key, stringifiedValue);
  }

  public getItem(key: string): T | null {
    let item: string | null = localStorage.getItem(key);
    if (item) return JSON.parse(item);
    return null;
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
